<template>
    <div>
        <div class="container-inner">
            <div class="p-image">
                <div
                    class="img-fluid"
                    :class="detail_data.fpic ? 'header_food_pic' : 'no-pic'"
                    :style="pic_style"
                ></div>
                <div class="cart-item fixed">
                    {{ $t('food.total_added') }}
                    {{ mix_fcount }}
                </div>
            </div>
            <div class="p-details">
                <div class="p-item border-b">
                    <div class="row">
                        <div class="col-8 col-xl-10">
                            <h1>
                                {{ translate('item', detail_data.fname) }}
                            </h1>
                        </div>
                        <div class="col-4 col-xl-2">
                            <h2 class="price" :class="{ price_tbd: detail_data.is_tbd }">
                                <div class="tbd" v-if="detail_data.is_tbd">TBD</div>
                                <template v-else> ${{ detail_data.fprice }} </template>
                            </h2>
                        </div>
                    </div>
                    <div v-if="detail_data.feature_tag" class="food_tag" style="color:green;">
                        <div class="img img_leaf"></div>
                        <div class="food_tag_text">
                            {{feature_tag}}
                        </div>
                    </div>
                    <div v-if="detail_data.allergy_tag" class="food_tag">
                        <div class="img img_alert"></div>
                        <div class="food_tag_text">
                            {{allergy_tag}}
                        </div>
                    </div>
                    <div class="pt-3 pr-5" style="font-size: 14px">
                        <div class="desc_item" v-for="(item, index) in desc" :key="index" v-html="item">
                        </div>
                    </div>
                </div>
                <!--                features-->
                <div class="border-b menus" v-if="detail_data.feature_default_value">
                    <div class="add-on-head">
                        <div class="row">
                            <div class="col-7">
                                <div class="ntitle">
                                    {{ translate('feature_name', detail_data.feature_name) }}
                                </div>
                                <div class="item_title_extra">({{ $t('food.choose_1') }})</div>
                            </div>
                            <div class="col-5 text-right">
                                <div class="legal">
                                    {{ $t('food.required') }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="menu"
                        :class="{ hilighted: feature_name === option_item.key }"
                        v-for="(option_item, index) in option_list"
                        :key="index"
                        @click="choose_option(option_item.key)"
                    >
                        <div class="row">
                            <div class="col-12">
                                <div class="addon-title">
                                    {{ option_item.value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  features-->
                <!-- .addons -->
                <div class="border-b menus" v-for="(item, j) in addon_list" :key="j">
                    <div class="add-on-head">
                        <div class="row">
                            <div class="col-7">
                                <div class="ntitle">
                                    {{ $t('food.addon') }}:
                                    {{ translate('addon_name', item.addon_name) }}
                                </div>
                            </div>
                            <div class="col-5 text-right">
                                <div class="legal">
                                    {{ item.addon_is_required==1 ? $t('food.required') : $t('food.optional') }}
                                </div>
                            </div>
                        </div>
                        <div class="item_title_extra" v-if="item.total_item_count > 0">
                            {{ $t('food.select_items', {count: item.total_item_count}) }}
                        </div>
                        <div class="item_title_extra" v-else-if="item.min_item_count > 0 && !item.max_item_count">
                            {{ $t('food.select_items_min', {count: item.min_item_count}) }}
                        </div>
                        <div class="item_title_extra" v-else-if="item.max_item_count > 0 && !item.min_item_count">
                            {{ $t('food.select_items_max', {count: item.max_item_count}) }}
                        </div>
                        <div class="item_title_extra" v-else-if="item.max_item_count > 0 && item.min_item_count>0">
                            {{ $t('food.select_items_min_max', {min: item.min_item_count, max: item.max_item_count}) }}
                        </div>
                        <div class="item_title_extra" v-if="!item.addon_choose_all">
                            {{ item.addon_choose_num_text }}
                        </div>
                    </div>

                    <div
                        class="menu"
                        v-for="(addon_item, k) in item.items"
                        :key="k"
                        :class="{ disabled: addon_item.availability === '0', hilighted: ifHighlight(addon_item, item) }"
                        @click="choose_addon(addon_item, item, $event)"
                    >
                        <div class="row">
                            <div class="col-6">
                                <div class="addon-title">
                                    {{ translate('addon_value', addon_item.name) }}
                                    <span class="font-italic">{{
                                        addon_item.availability == 1 ? '' : $t('food.soldout') 
                                    }}</span>
                                </div>
                            </div>
                            <div class="col-6 text-right">
                                <div class="addon-price">
                                    {{ addon_item.price >= 0 ? '+$' + addon_item.price : '-$' + addon_item.price * -1 }}
                                </div>
                            </div>
                        </div>
                        <!-- Quantity Box -->
                        <div class="quantity-box" v-show="item.multiple_choice == 1">
                            <div class="row">
                                <div class="col-6">
                                    <div class="qaction">
                                        <button
                                            class="mt-prev"
                                            @click="change_addon_count(addon_item, item, 'minus', $event)"
                                        >
                                            -
                                        </button>
                                        <span> {{ addon_count_dict[item.addon_name + '_' + addon_item.name] }}</span>
                                        <button
                                            class="mt-next"
                                            @click="change_addon_count(addon_item, item, 'add', $event)"
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- .quantity-box -->
                    </div>
                    <!-- .menu -->
                </div>

                <div class="border-b notes">
                    <div class="ntitle">{{ $t('food.note') }}</div>
                    <textarea :placeholder="$t('food.add_note')" v-model="note"></textarea>
                </div>
                <!-- .notes -->

                <div class="quantity">
                    <div class="qaction">
                        <button class="mt-prev" href="#" @click="change_food_count('minus')">-</button>
                        <span>{{ choose_count }}</span>
                        <button class="mt-next" href="#" @click="change_food_count('add')">+</button>
                    </div>
                </div>
                <!-- .quantity -->
            </div>
            <!-- .p-details -->
        </div>
        <div class="menu-footer" @click="do_save">
            <img v-if="is_loading" class="loading" src="@/assets/images/loading.svg" alt="" />
            <div class="row" v-else>
                <div class="col-8">
                    <div class="card-val">
                        <span>
                            {{
                                $t('food.add_to_cart', {count: choose_count})
                            }}
                        </span>
                    </div>
                </div>
                <div class="col-4 text-right">
                    <input class="per-item-price" type="hidden" value="35" />
                    <div class="t-price">
                        $<span class="pval">{{ total_price }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <a class="back" @click="goback"></a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.menu-footer {
    height: 65px;
}
.food_tag{
    font-size: 15px;
    display: flex;
    align-items: flex-start;
    font-size:14px;
    margin-top:6px;
    .food_tag_text{
        line-height: normal;
        overflow:hidden;
        // text-overflow:ellipsis;
        // white-space: nowrap;
    }
    img{
        margin-right: 7px;
        height: 13px;
    }
}
.food_tag .img_leaf{
    mask: url(../assets/images/leaf.svg) no-repeat;
    -webkit-mask: url(../assets/images/leaf.svg) no-repeat;
    background-color: green;
}
.food_tag .img_alert{
    mask: url(../assets/images/alert.svg) no-repeat;
    -webkit-mask: url(../assets/images/alert.svg) no-repeat;
    background-color: #d40606;
}
.food_tag .img{
    flex-shrink: 0;
    margin-right: 7px;
    height: 13px;
    width: 13px;
    background-size: contain;
    mask-size: 14px;
    -webkit-mask-size: 14px;
    margin-top: 2px;
}
.loading {
    margin: 0 auto;
    position: static;
    background-color: transparent;
    color: white;
    width: 20px;
    height: 20px;
    animation: rotate 2s infinite;
    animation-timing-function: linear;
}
.desc_item {
    word-break: break-all;
    word-break: break-all;
    line-height: 1.3;
    margin-bottom: 6px;
    &:last-child {
        margin-bottom: 0;
    }
}
.header_food_pic {
    min-height: 300px;
    min-width: 250px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}

.no-pic {
    min-height: 100px;
}

.item_title_extra {
    margin-top: 0.5rem;
}
</style>
<script>
import { createNamespacedHelpers, mapActions, mapGetters, mapMutations, mapState } from 'vuex';
// import {checkDailyLimit, checkOnceLimit} from '@/service/validates.js';
import validates from '@/service/validates.js';

const FoodStore = createNamespacedHelpers('food');
const APP = createNamespacedHelpers('App');
const Menu = createNamespacedHelpers('menu');
const Orders = createNamespacedHelpers('sharedorderdetail');

export default {
    name: 'FoodDetail',
    data() {
        return {
            value: '',
            addon_count_dict: {},
            choose_count: 1,
            addon_dict: {},
            addon_chosen_count: 0,
            addon_total_price: 0,
            feature_name: '',
            note: '',
            btn_choose_count: 1,
            detail_data: {},
            mix_fcount: 0,
            oid: this.$store.state.app.shared_oid || ''
        };
    },
    mounted() {
        if (Object.keys(this.food_dict) < 1) {
            this.toggleLoading(true);
        }
        this.initFood();
        if (this.oid) {
            this.loadOrderData({
                oid: this.oid.split('-')[0],
                cartid: this.$store.state.app.shared_cart_id,
                router: this.$router
            });
        }
    },
    watch: {
        food_dict(newList) {
            if (newList) {
                this.initFood();
                this.toggleLoading(false);
            }
        },
        cart_food_dict(newData) {
            /* eslint-disable no-prototype-builtins*/
            if (newData.hasOwnProperty(this.detail_data.fid)) {
                this.mix_fcount = newData[this.detail_data.fid].mix_fcount;
            }
        }
    },
    computed: {
        ...mapGetters({
            translate: 'app/translate',
            language: 'app/get_language',
            is_vip: 'app/get_if_is_vip',
        }),
        ...Menu.mapState(['food_dict', 'is_loading']),
        ...FoodStore.mapState(['pageOpen']),
        ...Orders.mapState({
            orderFoods: 'foods'
        }),
        feature_tag() {
            let tags = this.detail_data.feature_tag.split(',');
            tags = tags.map(item=>{
                return this.$t('food_tag.'+item);
            });
            return tags.join(', ');
        },
        allergy_tag() {
            let tags = this.detail_data.allergy_tag.split(',');
            tags = tags.map(item=>{
                return this.$t('food_tag.'+item);
            });
            if(this.language==='kor'){
              return tags.join(', ') + ' ' +this.$t('food_tag.may_contain')
            }else {
              return  this.$t('food_tag.may_contain') + ' ' + tags.join(', ')
            }
        },
        desc() {
            const desc = this.translate('food_desc', this.detail_data.fdesc)
            return desc.split('\n');
        },
        total_price() {
            let _price =
                this.is_vip && this.detail_data.fprice_vip ? this.detail_data.fprice_vip : this.detail_data.fprice;
            let total = (
                parseFloat(_price) * this.choose_count +
                parseFloat(this.addon_total_price) * this.choose_count
            ).toFixed(2);

            if (isNaN(total)) {
                return 0;
            }
            return total;
        },
        pic_style() {
            return 'background-image: url(' + this.detail_data.fpic + ');';
        },
        cart_food_dict() {
            return this.$store.state.app.cart_data.cart_food_dict || {};
        },
        option_list() {
            const list = this.detail_data.feature_values.split(',');
            const options = [];
            list.forEach((item) => {
                options.push({
                    key: item,
                    value: this.translate('feature_value', item)
                });
            });
            return options;
        },
        addon_list() {
            let addon_data = this.detail_data.addon_data;
            if (addon_data && addon_data.length > 0) {
                let addon_arr_data = [];
                for (let i = 0; i < addon_data.length; i++) {
                    let total_item_count = addon_data[i].total_item_count;
                    let min_item_count = addon_data[i].min_item_count;
                    let max_item_count = addon_data[i].max_item_count;
                    let addon_names = addon_data[i].addon_values_name.split('|');
                    let addon_prices = addon_data[i].addon_values_price.split('|');
                    let addon_availabilitys = addon_data[i].addon_values_availability.split('|');
                    let addon_one_item = [];
                    for (let i = 0, len = addon_names.length; i < len; i++) {
                        addon_one_item.push({
                            name: addon_names[i],
                            price: addon_prices[i] || '0.00',
                            availability: addon_availabilitys[i]
                        });
                    }

                    let temp_addon_choose_num = '';
                    let _num = addon_data[i].addon_available_number;
                    temp_addon_choose_num = `${ this.$t('food.choose') } 1 type`;

                    if (_num == 'all') {
                        _num = addon_names.length;
                    }

                    if (parseInt(_num) > 1) {
                        temp_addon_choose_num = `(${ this.$t('food.choose') } 1 - ${_num} types)`;
                    }

                    addon_arr_data.push({
                        addon_available_number: addon_data[i].addon_available_number,
                        addon_choose_num_text: temp_addon_choose_num,
                        addon_choose_all: (_num=='all' || _num===addon_one_item.length)?1:0,
                        addon_id: addon_data[i].addon_id,
                        addon_is_required: addon_data[i].addon_is_required,
                        addon_name: addon_data[i].addon_name,
                        addon_name_chn: addon_data[i].addon_name_chn,
                        multiple_choice: addon_data[i].multiple_choice,
                        items: addon_one_item,
                        total_item_count: total_item_count,
                        min_item_count: min_item_count,
                        max_item_count: max_item_count
                    });
                }

                return addon_arr_data;
            }

            return [];
        }
    },
    methods: {
        ...mapActions({
            edit_food: 'app/edit_cart_food',
            set_msg: 'app/setToast',
            loadOrderData: 'sharedorderdetail/initData',
            loadMenuFood: 'menu/initData'
        }),
        ...mapMutations({
            add_food: 'app/APP_ADD_FOOD_INTO_CART',
            del_food: 'app/APP_DEL_FOOD_INTO_CART',
            close_detail: 'menu/MENU_TOGGLE_DETAIL',
            toggleLoading: 'app/TOGGLE_LOADING'
        }),

        initFood() {
            const fid = this.$route.query.id;
            this.detail_data = { ...this.food_dict[fid] };
            // has data, feature not selected OR feature names changed
            if (this.detail_data && (!this.feature_name || !this.featureNameInDetail())) {
                this.feature_name = this.detail_data.feature_default_value || '';
            }
        },

        featureNameInDetail() {
            const { feature_values } = this.detail_data;
            // if this value changed to null, most of the time it means there was error when fetching data
            if (!feature_values) {
                return true;
            }
            const features = feature_values.split(',');
            return features.includes(this.feature_name);
        },

        async do_save() {
            if (this.is_loading) return;

            await validates.checkDailyLimit({ fid: this.detail_data.fid, that: this, count: this.choose_count });
            await validates.checkOnceLimit({ fid: this.detail_data.fid, that: this, count: this.choose_count });
            await validates.checkOnceSubmitLimit({ fid: this.detail_data.fid, that: this, count: this.choose_count });

            let _food_data = JSON.parse(JSON.stringify(this.detail_data));

            let addon_names = [];
            let addon_prices = [];
            let addon_counts = [];

            if (this.detail_data.is_out_of_stock == 1) {
                this.set_msg(this.$t('food.outof_stock'));
                return;
            }

            for (let o in this.addon_dict) {
                if (this.addon_dict[o] && this.addon_dict[o].length > 0) {
                    let items = this.addon_dict[o];
                    let one_addon_names = [];
                    let one_addon_prices = [];
                    let one_addon_counts = [];
                    for (let i = 0; i < items.length; i++) {
                        one_addon_names.push(items[i].item_name);
                        one_addon_prices.push(items[i].item_price);
                        one_addon_counts.push(this.addon_count_dict[o + '_' + items[i].item_name]);
                    }
                    addon_names.push(one_addon_names.join('|'));
                    addon_prices.push(one_addon_prices.join('|'));
                    addon_counts.push(one_addon_counts.join('|'));
                }
            }

            let false_flag = false;
            for (let i = 0; i < this.addon_list.length; i++) {
                const addonItem = this.addon_list[i];
                if (addonItem.addon_is_required == 1) {
                    if (
                        !this.addon_dict[addonItem.addon_name] ||
                        (this.addon_dict[addonItem.addon_name] && this.addon_dict[addonItem.addon_name].length == 0)
                    ) {
                        this.set_msg({
                            msg: this.$t('food.choose_addon')
                        });
                        false_flag = true;
                        break;
                    }
                }

                let choose_addons = this.addon_dict[addonItem.addon_name] || [];
                let count = 0;
                choose_addons.forEach((item) => {
                    count = count + this.addon_count_dict[addonItem.addon_name + '_' + item.item_name];
                });
                if (addonItem.total_item_count > 0 && count > 0 && count != addonItem.total_item_count) {
                    this.set_msg({
                        msg:
                            '<div>[<span class="text-truncate addon-name-toast">' +
                            this.translate('addon_name', addonItem.addon_name) +
                            '</span>]</div>' +
                            (this.$t('food.please_choose')) +
                            addonItem.total_item_count +
                            (this.$t('food.addons'))
                    });
                    false_flag = true;
                    break;
                }
                if(count>0 && addonItem.min_item_count > 0 && count < addonItem.min_item_count) {
                    this.set_msg({
                        msg: this.$t('food.min_addon_count_tip', {count: addonItem.min_item_count, addon_name: this.translate('addon_name', addonItem.addon_name)})
                    });
                    false_flag = true;
                    break;                    
                }
                if(addonItem.max_item_count > 0 && count > addonItem.max_item_count) {
                    this.set_msg({
                        msg: this.$t('food.max_addon_count_tip', {count: addonItem.max_item_count, addon_name: this.translate('addon_name', addonItem.addon_name)})
                    });
                    false_flag = true;
                    break;                    
                }
            }

            if (false_flag) {
                return;
            }

            _food_data.fcount = this.choose_count;
            _food_data.fprice_vip = _food_data.fprice_vip ? _food_data.fprice_vip : '';

            let save_data = {
                cartid: this.$store.state.app.shared_cart_id,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.email,
                uname: this.$store.state.app.uname,
                router: this.$router,
                type: 'add',
                is_vip: this.is_vip,

                food_data: _food_data,
                extra_data: {
                    feature_name: this.feature_name,
                    note: this.note,
                    addon_names: addon_names.join('@@'),
                    addon_prices: addon_prices.join('@@'),
                    addon_counts: addon_counts.join('@@'),
                    addon_total_price: this.addon_total_price,
                    add_count: this.choose_count
                }
            };

            this.edit_food(save_data);
        },
        change_food_count(type) {
            if (type === 'add') {
                this.choose_count += 1;
            } else if (type === 'minus' && this.choose_count > 1) {
                this.choose_count -= 1;
            }
        },
        choose_option(feature_name) {
            this.feature_name = feature_name;
        },
        choose_addon(addon_item, addon_all_data, e) {
            // not available
            if (addon_item.availability == '0') {
                return;
            }
            const name = addon_all_data.addon_name + '_' + addon_item.name;

            // addon最大为1，并且其中一个已经勾选，其他没被勾选的被点击
            if (
                !this.isAddonAdded(addon_item, addon_all_data) &&
                addon_all_data.addon_available_number != 'all' &&
                this.addon_dict[addon_all_data.addon_name]?.length &&
                parseInt(addon_all_data.addon_available_number) === 1
            ) {
                const choosed = this.addon_dict[addon_all_data.addon_name][0];
                choosed.price = choosed.item_price;
                choosed.name = choosed.item_name;
                const nameChoosed = addon_all_data.addon_name + '_' + choosed.item_name;
                this.addonUnChoose(choosed, addon_all_data, nameChoosed);
                this.addonChoose(addon_item, addon_all_data, name);
                this.$nextTick(function () {
                    if (this.total_price < 0) {
                        this.addonChoose(choosed, addon_all_data, nameChoosed);
                        this.addonUnChoose(addon_item, addon_all_data, name);
                    }
                });
                return;
            }

            // exceeded the max amount
            if (
                !this.isAddonAdded(addon_item, addon_all_data) &&
                addon_all_data.addon_available_number != 'all' &&
                this.addon_dict[addon_all_data.addon_name] &&
                this.addon_dict[addon_all_data.addon_name].length >= parseInt(addon_all_data.addon_available_number)
            ) {
                return;
            }

            if (this.isAddonAdded(addon_item, addon_all_data)) {
                this.addonUnChoose(addon_item, addon_all_data, name);
                this.$nextTick(function () {
                    if (this.total_price < 0) {
                        this.addonChoose(addon_item, addon_all_data, name);
                    }
                });
            } else {
                this.addonChoose(addon_item, addon_all_data, name);
                this.$nextTick(function () {
                    if (this.total_price < 0) {
                        this.addonUnChoose(addon_item, addon_all_data, name);
                    }
                });
            }
            this.addon_name = addon_name;
        },
        addonChoose(addon_item, addon_all_data, name) {
            if (!this.addon_dict[addon_all_data.addon_name]) {
                this.addon_dict[addon_all_data.addon_name] = [];
                this.addon_count_dict[addon_all_data.addon_name + '_dict'] = {};
            }
            this.$set(this.addon_count_dict, name, 1);

            this.addon_dict[addon_all_data.addon_name].push({
                item_name: addon_item.name,
                item_price: addon_item.price
            });
            this.addon_total_price = (parseFloat(this.addon_total_price) + parseFloat(addon_item.price)).toFixed(2);
            this.addon_chosen_count = this.addon_chosen_count + 1;
        },
        addonUnChoose(addon_item, addon_all_data, name) {
            const addonTotalPrice = addon_item.price * this.addon_count_dict[name];
            const itemList = JSON.stringify(this.addon_dict[addon_all_data.addon_name]);
            let addon_item_list = JSON.parse(itemList);
            for (let i = 0; i < addon_item_list.length; i++) {
                if (addon_item_list[i].item_name === addon_item.name) {
                    addon_item_list.splice(i, 1);
                    this.$set(this.addon_count_dict, name, 0);
                    delete this.addon_count_dict[name];
                }
            }
            this.addon_dict[addon_all_data.addon_name] = addon_item_list;
            this.addon_total_price = (parseFloat(this.addon_total_price) - parseFloat(addonTotalPrice)).toFixed(2);
            this.addon_chosen_count = this.addon_chosen_count - 1;
        },
        ifHighlight(addon_item, addon_all_data) {
            const itemName = addon_item.name;
            const addonName = addon_all_data.addon_name;
            return this.addon_dict[addonName]?.some((item) => {
                return item.item_name === itemName;
            });
        },
        change_addon_count(addon_item, item, type, e) {
            e.stopPropagation();
            let now_value = this.addon_count_dict[item.addon_name + '_' + addon_item.name];
            if (type === 'minus' && now_value > 1) {
                this.$set(this.addon_count_dict, item.addon_name + '_' + addon_item.name, now_value - 1);
                this.addon_total_price = (parseFloat(this.addon_total_price) - parseFloat(addon_item.price)).toFixed(2);
                this.addon_total_price_pickup = (
                    parseFloat(this.addon_total_price_pickup) - parseFloat(addon_item.price * item.pickup_discount)
                ).toFixed(2);

                this.$nextTick(function () {
                    if (this.total_price < 0) {
                        this.change_addon_count(addon_item, item, 'add', e);
                    }
                });
            }
            if (type === 'add') {
                this.$set(this.addon_count_dict, item.addon_name + '_' + addon_item.name, now_value + 1);
                this.addon_total_price = (parseFloat(this.addon_total_price) + parseFloat(addon_item.price)).toFixed(2);
                this.addon_total_price_pickup = (
                    parseFloat(this.addon_total_price_pickup) + parseFloat(addon_item.price * item.pickup_discount)
                ).toFixed(2);
                this.$nextTick(function () {
                    if (this.total_price < 0) {
                        this.change_addon_count(addon_item, item, 'minus', e);
                    }
                });
            }
        },
        isAddonAdded(addonItem, addon) {
            const name = addon.addon_name + '_' + addonItem.name;
            return Object.keys(this.addon_count_dict).includes(name);
        },
        isAddonInclude(addon) {
            const name = addon.addon_name;
            const names = addon.items.map((item) => name + '_' + item.name);
            /* eslint-disable no-prototype-builtins*/
            return names.some((n) => this.addon_count_dict.hasOwnProperty(n));
        },
        // dirty hack for a weired bug, to remove /add class to the element
        toggleClass(el, name) {
            let classes = el.className;
            const index = classes.indexOf(name);
            if (index > 0) {
                const newName = classes.replace(new RegExp(name), '');
                el.className = newName;
                return;
            }
            el.className = classes + ' ' + name;
        },
        goback() {
            this.$router.push({ path: '/menu' });
        }
    }
};
</script>
