// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/leaf.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/alert.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".menu-footer[data-v-d96f6706]{height:65px}.food_tag[data-v-d96f6706]{font-size:15px;display:flex;align-items:flex-start;font-size:14px;margin-top:6px}.food_tag .food_tag_text[data-v-d96f6706]{line-height:normal;overflow:hidden}.food_tag img[data-v-d96f6706]{margin-right:7px;height:13px}.food_tag .img_leaf[data-v-d96f6706]{mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-color:green}.food_tag .img_alert[data-v-d96f6706]{mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;-webkit-mask:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-color:#d40606}.food_tag .img[data-v-d96f6706]{flex-shrink:0;margin-right:7px;height:13px;width:13px;background-size:contain;mask-size:14px;-webkit-mask-size:14px;margin-top:2px}.loading[data-v-d96f6706]{margin:0 auto;position:static;background-color:transparent;color:#fff;width:20px;height:20px;-webkit-animation:rotate 2s infinite;animation:rotate 2s infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear}.desc_item[data-v-d96f6706]{word-break:break-all;line-height:1.3;margin-bottom:6px}.desc_item[data-v-d96f6706]:last-child{margin-bottom:0}.header_food_pic[data-v-d96f6706]{min-height:300px;min-width:250px;background-size:cover;background-position:50%;border-radius:10px}.no-pic[data-v-d96f6706]{min-height:100px}.item_title_extra[data-v-d96f6706]{margin-top:.5rem}", ""]);
// Exports
module.exports = exports;
